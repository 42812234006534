import React from "react";
import styled from "styled-components";
import { FaPaintRoller, FaTools, FaDesktop } from "react-icons/fa";

// Images
import AddImage1 from "../../assets/img/add/one.jpg";
import AddImage2 from "../../assets/img/add/two.jpg";
import AddImage3 from "../../assets/img/add/three.jpg";
import AddImage4 from "../../assets/img/add/four.jpg";

// Data for Services
const services = [
  {
    icon: <FaPaintRoller />,
    title: "Interior Design",
    subtitle: "Customizing interiors to reflect personality and functionality while improving aesthetics.",
  },
  {
    icon: <FaDesktop />,
    title: "Decoration",
    subtitle: "Enhancing spaces to improve usability and visual appeal.",
  },
  {
    icon: <FaTools />,
    title: "Repair Services",
    subtitle: "Providing expert repair solutions for homes and offices.",
  },
];

const additionalServices = [
  "False Ceiling Installation",
  "Painting Services",
  "Plumbing Work",
  "Electrical Work",
  "Deep Cleaning",
  "Fabrication Services",
  "Personalized Consultations",
  "Affordable and Unique Solutions",
  "Property Value Enhancement",
];

export default function Services() {
  return (
    <Wrapper id="services">
      {/* Client Slider Section */}
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          {/* Replace with actual ClientSlider component */}
          <h2>Client Testimonials Slider Here</h2>
        </div>
      </div>

      {/* Services Section */}
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Services We Provide</h1>
            <p className="font20 bold">
              Interior design, decoration, and repair services tailored to your needs.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            {services.map((service, index) => (
              <ServiceBoxWrapper key={index}>
                <IconWrapper>{service.icon}</IconWrapper>
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceSubtitle>{service.subtitle}</ServiceSubtitle>
              </ServiceBoxWrapper>
            ))}
          </ServiceBoxRow>
        </div>

        {/* Additional Services Section */}
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">Also Our Company Offers:</h2>
                <ServiceGrid>
                  {additionalServices.map((service, index) => (
                    <ServiceCard key={index}>{service}</ServiceCard>
                  ))}
                </ServiceGrid>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="Stylish office interior" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="Modern living room design" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="Creative workspace design" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="Elegant interior design" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const ServiceBoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

const ServiceBoxWrapper = styled.div`
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  padding: 30px;
  border: 1px solid #eaeaea;
  border-radius: 15px;
  text-align: center;
  background-color: #fff;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  font-size: 40px;
  color: #000;
  margin-bottom: 15px;
`;

const ServiceTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #6a1b9a;
`;

const ServiceSubtitle = styled.p`
  font-size: 16px;
  color: #333;
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 860px) {
    flex-direction: column;
    padding: 30px 0;
    margin: 40px 0;
  }
`;

const AddLeft = styled.div`
  flex: 1;
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
`;

const AddRight = styled.div`
  flex: 1;
`;

const AddRightInner = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AddImgWrapp1 = styled.div`
  flex: 1;
  margin: 10px;

  img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  }
`;

const AddImgWrapp2 = styled(AddImgWrapp1)``;

const AddImgWrapp3 = styled(AddImgWrapp1)`
  flex: 2;
`;

const AddImgWrapp4 = styled(AddImgWrapp1)`
  flex: 3;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const ServiceCard = styled.div`
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  text-align: center;
  background-color: #f9f9f9;
  font-weight: semibold;

  &:hover {
    background-color: #f0f0f0;
  }
`;
