import React from "react";
import styled from "styled-components";
import { FaRegThumbsUp, FaRegClock, FaDollarSign, FaHome, FaPalette } from "react-icons/fa";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Why Choose Us?</h1>
            <p className="font13">Here are five reasons why we stand out:</p>
          </HeaderInfo>
          <div className="row">
            <PointsWrapper>
              <PointBox>
                <FaRegThumbsUp className="icon" />
                <h3>Improve Your Space</h3>
              </PointBox>
              <PointBox>
                <FaRegClock className="icon" />
                <h3>Save Time</h3>
              </PointBox>
              <PointBox>
                <FaDollarSign className="icon" />
                <h3>Save Money</h3>
              </PointBox>
              <PointBox>
                <FaHome className="icon" />
                <h3>Increase Property Value</h3>
              </PointBox>
              <PointBox>
                <FaPalette className="icon" />
                <h3>Creative Expression</h3>
              </PointBox>
            </PointsWrapper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    font-weight: 800;
  }

  p {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
  }
`;

const PointsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const PointBox = styled.div`
  background: #ffffff;
  padding: 20px;
  width: 220px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  .icon {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #003366; /* Dark blue color */
    margin: 0;
  }
`;
