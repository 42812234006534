import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/final.jpg";
import ProjectImg2 from "../../assets/img/projects/final.jpg";
import ProjectImg3 from "../../assets/img/projects/final.jpg";
import ProjectImg4 from "../../assets/img/projects/final.jpg";
import ProjectImg5 from "../../assets/img/projects/final.jpg";
import ProjectImg6 from "../../assets/img/projects/final.jpg";


export default function Projects() {
  const projectData = [
    {
      img: ProjectImg1,
      title: "Modern Living Room",
      text: "A sleek and contemporary living room design with neutral tones and minimalist furniture.",
    },
    {
      img: ProjectImg2,
      title: "Cozy Bedroom Makeover",
      text: "Transforming a bedroom into a warm, relaxing retreat with soft lighting and comfortable furnishings.",
    },
    {
      img: ProjectImg3,
      title: "Elegant Office Space",
      text: "Creating a professional yet inviting office with ergonomic furniture and stylish decor.",
    },
    {
      img: ProjectImg4,
      title: "Luxurious Kitchen Remodel",
      text: "Upgrading a kitchen with modern appliances, custom cabinetry, and elegant lighting.",
    },
    {
      img: ProjectImg5,
      title: "Chic Dining Area",
      text: "Designing a dining area that combines functionality with sophistication for family gatherings.",
    },
    {
      img: ProjectImg6,
      title: "Outdoor Patio Design",
      text: "A beautiful patio space with comfortable seating and greenery for outdoor relaxation.",
    },
  ];

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
              Explore some of the remarkable interior design projects we've completed. Each project reflects our
              commitment to quality and creativity.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {projectData.map((project, index) => (
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" key={index}>
                <ProjectBox
                  img={project.img}
                  title={project.title}
                  text={project.text}
                  action={() => alert(`Clicked on ${project.title}`)}
                />
              </div>
            ))}
          </div>
          
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <br />
          <br />
        </div>
      </div>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;

const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

