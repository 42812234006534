import React from "react";
import styled from "styled-components";
import { FaHandshake, FaClipboardList } from "react-icons/fa";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="pricingTitle">Our Pricing</h1>
            <h2 className="subTitle">Your comfort is very essential!</h2>
          </HeaderInfo>
          <ContentWrapper>
            <Box>
              <FaHandshake className="icon" />
              <p>
                We are professionals who focus on each client's specific demands, requirements, and budget. 
                That is exactly why we do not provide package prices. Each project is unique to us, and 
                designing and implementing it takes various amounts of time.
              </p>
            </Box>
            <Box>
              <FaClipboardList className="icon" />
              <p>
                In order to avoid writing lengthy explanations of how and why we are unable to present you 
                with an accurate quote, we would appreciate it if you could tell us about your project in 
                person. We will do our best to make a customized offer for you.
              </p>
            </Box>
          </ContentWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  background-color: #f9f9f9;
`;

const HeaderInfo = styled.div`
  margin-bottom: 40px;
  text-align: center;

  .pricingTitle {
    font-size: 3rem;
    font-weight: 800;
    color: #6c63ff; /* Purple color */
    font-family: 'Playfair Display', serif; /* Elegant font */
    margin-bottom: 10px;
  }

  .subTitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    font-family: 'Roboto', sans-serif; /* Clean font */
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const Box = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 45%;
  text-align: center;
  transition: transform 0.3s ease;

  @media (max-width: 860px) {
    width: 100%;
  }

  &:hover {
    transform: translateY(-5px);
  }

  .icon {
    font-size: 3rem;
    color: #6c63ff; /* Purple color */
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
  }
`;
