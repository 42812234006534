import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/img/nestdone.png";

export default function Contact() {
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <FooterWrapper>
      <FooterContainer className="darkBg">
        <div className="container">
          <InnerWrapper>
            {/* Logo and Brand Name */}
            <BrandWrapper>
              <Link className="flexCenter pointer" to="home" smooth={true} offset={-80}>
                <LogoBox>
                  <Logo src={LogoImg} alt="Nest Interior Logo" />
                  <BrandName>Nest Interior</BrandName>
                </LogoBox>
              </Link>
            </BrandWrapper>

            {/* Contact Details */}
            <ContactDetails>
              <ContactItem>
                <h3>Address:</h3>
                <p>
                  House no 43<br />
                  Kumar Temple view phase 2 l, behind<br />
                  Gutte Anjeneta swamy temple kodipalya main road<br />
                  Kengeri 560060
                </p>
              </ContactItem>
              <ContactItem>
                <h3>Phone:</h3>
                <a href="tel:+919538301496">+91 95383 01496</a>
              </ContactItem>
              <ContactItem>
                <h3>Google Maps:</h3>
                <a
                  href="https://maps.app.goo.gl/nB5w6K7ki7y9t9r28"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Location
                </a>
              </ContactItem>
            </ContactDetails>

            {/* Footer Links */}
            <FooterLinks>
              <FooterLink to="home" smooth={true} offset={-80}>
                Home
              </FooterLink>
              <FooterLink to="services" smooth={true} offset={-80}>
                Services
              </FooterLink>
              <FooterLink to="pricing" smooth={true} offset={-80}>
                Pricing
              </FooterLink>
              <FooterLink to="contact" smooth={true} offset={-80}>
                Contact
              </FooterLink>
            </FooterLinks>

            {/* Footer Bottom */}
            <FooterBottom>
              <Copyright>
                © {getCurrentYear()} - <span>Nest Interior</span>. All Rights Reserved.
              </Copyright>
              <BackToTop to="home" smooth={true} offset={-80}>
                Back to Top
              </BackToTop>
            </FooterBottom>
          </InnerWrapper>
        </div>
      </FooterContainer>
    </FooterWrapper>
  );
}

// Styled Components
const FooterWrapper = styled.div`
  width: 100%;
`;

const FooterContainer = styled.div`
  background: #282828; /* Dark background for footer */
  padding: 40px 0;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff; /* White background for the box */
  padding: 10px 20px; /* Add padding for spacing */
  border-radius: 8px; /* Optional: add border radius for rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
`;

const BrandName = styled.h1`
  font-size: 1.5rem;
  font-weight: 800;
  color: #282828; /* Dark color for the text */
  margin-left: 15px;
  font-family: "Playfair Display", serif;
`;

const ContactDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
  text-align: center;
`;

const ContactItem = styled.div`
  h3 {
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
  }

  p, a {
    font-size: 0.9rem;
    color: #6c63ff; /* Purple for consistency */
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const FooterLink = styled(Link)`
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #6c63ff; /* Purple hover effect */
  }
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  color: #ffffff;

  span {
    color: #6c63ff; /* Purple highlight for "Nest Interior" */
    font-weight: bold;
  }
`;

const BackToTop = styled(Link)`
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #6c63ff; /* Purple hover effect */
  }
`;
